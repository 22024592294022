import axios from 'axios'
import AuthService from './auth-header'

// /*dev*/ const API_URL = 'http://192.168.1.81:5000/api/';
// /*prod*/ const API_URL = 'https://ats.sattvahuman.in/api/';

let API_URL = ''
if (process.env.NODE_ENV === 'production') {
  API_URL = 'https://ats.sattvahuman.in/api/'
} else {
  API_URL = 'http://192.168.1.81:5000/api/'
}

class PayrollService {

  /**
   * Bulk upload employees
   * POST /employees/bulk-upload
   */
  async bulkUploadEmployees(employees) {
    try {
      const res = await axios.post(
        API_URL + 'employees/bulk-upload', 
        employees, 
        { headers: AuthService.authHeaderForm() }
      )
      return res.data
    } catch (err) {
      console.log(err.response)
      return err.response
    }
  }

  /**
   * Get employees (with optional query params)
   * GET /employees?department=XYZ&...
   */
  async getEmployees(department, name) {
    try {
      let url = API_URL + 'employees'
      const params = []
      if (department) params.push('department=' + department)
      if (name) params.push('name=' + name)
      if (params.length) url += '?' + params.join('&')

      const res = await axios.get(url, {
        headers: AuthService.authHeader()
      })
      return res.data
    } catch (err) {
      console.log(err.response)
      return err.response
    }
  }

  /**
   * Create or Update Department Rule
   * POST /department/rules
   */
  async createOrUpdateDepartmentRule(ruleData) {
    try {
      const res = await axios.post(
        API_URL + 'department/rules', 
        ruleData, 
        { headers: AuthService.authHeaderForm() }
      )
      return res.data
    } catch (err) {
      console.log(err.response)
      return err.response
    }
  }

  /**
   * Calculate Payroll for a given month/year
   * POST /calculate
   */
  async calculatePayroll(data) {
    // data = { month, year, ... }
    try {
      const res = await axios.post(
        API_URL + 'calculate', 
        data,
        { headers: AuthService.authHeaderForm() }
      )
      return res.data
    } catch (err) {
      console.log(err.response)
      return err.response
    }
  }

  /**
   * Get payrolls
   * GET /payrolls?department=...&month=...&year=...&status=...
   */
  async getPayrolls({ department, month, year, status }) {
    try {
      let url = API_URL + 'payrolls'
      const params = []
      if (department) params.push('department=' + department)
      if (month) params.push('month=' + month)
      if (year) params.push('year=' + year)
      if (status) params.push('status=' + status)
      if (params.length) url += '?' + params.join('&')

      const res = await axios.get(url, {
        headers: AuthService.authHeader()
      })
      return res.data
    } catch (err) {
      console.log(err.response)
      return err.response
    }
  }

  /**
   * Update salary status of a single payroll record
   * PATCH /payroll/status
   * Body: { payrollId, status }
   */
  async updateSalaryStatus(payrollId, status) {
    try {
      const res = await axios.patch(
        API_URL + 'payroll/status',
        { payrollId, status },
        { headers: AuthService.authHeaderForm() }
      )
      return res.data
    } catch (err) {
      console.log(err.response)
      return err.response
    }
  }

  /**
   * Bulk update salary status
   * POST /payroll/bulk-status-update
   * Body: { payrollIds: [], status: 'Hold'|'Pay'|'Paid' }
   */
  async bulkUpdateSalaryStatus(payrollIds, status) {
    try {
      const res = await axios.post(
        API_URL + 'payroll/bulk-status-update',
        { payrollIds, status },
        { headers: AuthService.authHeaderForm() }
      )
      return res.data
    } catch (err) {
      console.log(err.response)
      return err.response
    }
  }

  /**
   * Get summary
   * GET /summary?department=...&month=...&year=...
   */
  async getSummary({ department, month, year }) {
    try {
      let url = API_URL + 'summary'
      const params = []
      if (department) params.push('department=' + department)
      if (month) params.push('month=' + month)
      if (year) params.push('year=' + year)
      if (params.length) url += '?' + params.join('&')

      const res = await axios.get(url, {
        headers: AuthService.authHeader()
      })
      return res.data
    } catch (err) {
      console.log(err.response)
      return err.response
    }
  }

}

export default new PayrollService()
