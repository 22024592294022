<template>
    <b-table
      :items="employees"
      :fields="fields"
      key-field="empId"
      striped
      hover
      responsive
    >
      <template #cell(Name)="data">
        {{ data.item.Name }}
      </template>
      <template #cell(department)="data">
        {{ data.item.department }}
      </template>
      <template #cell(ctc)="data">
        {{ data.item.ctc }}
      </template>
    </b-table>
  </template>
  
  <script>
  import { BTable} from 'bootstrap-vue'
  
  export default {
    name: 'EmployeeTable',
    components: {
        BTable,
    },    
    props: {
      employees: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        fields: [
          { key: 'empId', label: 'Emp ID' },
          { key: 'Name', label: 'Name' },
          { key: 'department', label: 'Department' },
          { key: 'ctc', label: 'CTC' },
        ],
      };
    },
  };
  </script>
  