<template>
    <b-sidebar
      title="Department Rules"
      :visible="visible"
      @hidden="onHide"
      bg-variant="light"
      right
    >
      <div class="p-2">
        <b-form-group label="Department">
          <!-- <b-form-input v-model="rule.department" placeholder="e.g. Sales" /> -->
          <v-select
                      v-model="rule.department"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="title"
                      :options="departmentList"
                      :reduce="(option) => option.title"
                      
                  />
        </b-form-group>
  
        <b-form-group label="Calculation Base">
          <!-- <b-form-select v-model="rule.calculationBase">
            <b-form-select-option value="inhand">Inhand</b-form-select-option>
            <b-form-select-option value="gross">Gross</b-form-select-option>
          </b-form-select> -->
          <v-select
            v-model="rule.calculationBase"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="title"
            :options="baseOptions"
            :reduce="(option) => option.value"
            
            />
        </b-form-group>
  
        <b-form-group label="Structure Type">
          <!-- <b-form-select v-model="rule.structureType">
            <b-form-select-option value="percentage">Percentage</b-form-select-option>
            <b-form-select-option value="minimum_wage">Minimum Wage</b-form-select-option>
          </b-form-select> -->
          <v-select
            v-model="rule.structureType"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="title"
            :options="structureOptions"
            :reduce="(option) => option.value"
            
            />
        </b-form-group>
  
        <b-form-group label="Pay Days">
          <b-form-input type="number" v-model="rule.pay_days" />
        </b-form-group>
  
        <!-- If percentage-based, show categories. If minimum wage, show wage fields. -->
        <div v-if="rule.structureType === 'percentage'">
          <b-button variant="primary" size="sm" @click="addCategory">
            Add Category
          </b-button>
          <div
            v-for="(cat, index) in rule.categories"
            :key="index"
            class="mt-2 d-flex"
          >
            <b-form-input
              class="mr-2"
              v-model="cat.name"
              placeholder="Category name (basic, da, etc.)"
            />
            <b-form-input
              class="mr-2"
              type="number"
              v-model="cat.value"
              placeholder="Percentage value"
            />
            <b-button variant="danger" size="sm" @click="removeCategory(index)">
              Remove
            </b-button>
          </div>
        </div>
  
        <div v-else-if="rule.structureType === 'minimum_wage'" class="mt-2">
          <b-form-group label="Min Wage Basic">
            <b-form-input type="number" v-model="rule.minWageBasic" />
          </b-form-group>
          <b-form-group label="Min Wage DA">
            <b-form-input type="number" v-model="rule.minWageDA" />
          </b-form-group>
          <b-form-group label="Min Wage Others">
            <b-form-input type="number" v-model="rule.minWageOthers" />
          </b-form-group>
        </div>
  
        <b-button variant="success" class="mt-3" @click="saveRule">Save Rule</b-button>
      </div>
    </b-sidebar>
  </template>
  
  <script>
  import { BButton, BSidebar, BFormGroup, BFormInput} from 'bootstrap-vue'
  import vSelect from 'vue-select'
  import payrollService from '@/services/payrollService';
  
  export default {
    name: 'DepartmentRuleSidebar',
    components: {
        BButton, BSidebar, BFormGroup, BFormInput, vSelect
    },
    props: {
      visible: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        rule: {
          department: '',
          
          pay_days: 30,
          calculationBase: 'inhand',
          structureType: 'percentage',
          categories: [],
          minWageBasic: 0,
          minWageDA: 0,
          minWageOthers: 0,
        },
        baseOptions: [
            {"title": "Inhand", "value": "inhand"},
            {"title": "Gross", "value": "gross"},
        ],
        structureOptions: [
            {"title": "Percentage", "value": "percentage"},
            {"title": "Minimum Wage", "value": "minimum_wage"},
        ],
        departmentList: [{"title": "Sattva"},{"title": "HPE"},{"title": "KPMG"},{"title": "Nikitha"},{"title": "LnT"},{"title": "Reliance"},{"title": "William Sonoma"},{"title": "Hinduja"},{"title": "Adani Assam"},{"title": "Adani Bihar"},{"title": "Adani Mumbai"}], // or fetch dynamically
      };
    },
    methods: {
      onHide() {
        this.$emit('update:visible', false);
      },
      addCategory() {
        if (!this.rule.categories) {
          this.rule.categories = [];
        }
        this.rule.categories.push({ name: '', value: 0 });
      },
      removeCategory(index) {
        this.rule.categories.splice(index, 1);
      },
      saveRule() {
        // Convert categories to JSON if needed
        if (this.rule.structureType === 'percentage') {
          this.rule.categories = this.rule.categories.map(c => ({
            name: c.name,
            value: Number(c.value),
          }));
        }
  
        payrollService
          .createOrUpdateDepartmentRule(this.rule)
          .then(() => {
            this.$bvToast.toast('Rule saved successfully', {
              title: 'Success',
              variant: 'success',
            });
            this.onHide();
          })
          .catch((err) => {
            this.$bvToast.toast('Error saving rule', {
              title: 'Error',
              variant: 'danger',
            });
            console.error(err);
          });
      },
    },
  };
  </script>
  <style scoped lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
  
</style>