<template>
    <div>
      <b-card>
        <h4 class="mb-3">Employee List</h4>
  
        <!-- Filters -->
        <b-form inline class="mb-2">
          <b-form-group label="Search:" label-size="sm">
            <b-form-input
              size="sm"
              v-model="searchTerm"
              placeholder="Search by name/email"
            ></b-form-input>
          </b-form-group>
  
          <b-form-group label="Department:" label-size="sm" class="ml-3">
            <!-- <b-form-select size="sm" v-model="departmentFilter">
              <b-form-select-option value="">All</b-form-select-option>
              <b-form-select-option
                v-for="dep in departmentList"
                :key="dep"
                :value="dep"
              >
                {{ dep }}
              </b-form-select-option>
            </b-form-select> -->
            <v-select
                      v-model="departmentFilter"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="title"
                      :options="departmentList"
                      :reduce="(option) => option.title"
                      multiple
                      :close-on-select="false"
                  />
          </b-form-group>
  
          <b-button size="sm" variant="primary" class="ml-2" @click="fetchEmployees">
            Filter
          </b-button>
  
          <b-button size="sm" variant="info" class="ml-2" @click="showSidebar">
            Manage Dept Rules
          </b-button>
  
          <b-button size="sm" variant="success" class="ml-auto" @click="openBulkUploadModal">
            Bulk Upload
          </b-button>
        </b-form>
  
        <!-- Employee Table -->
        <employee-table :employees="filteredEmployees" />
      </b-card>
  
      <!-- Department Rule Sidebar -->
      <department-rule-sidebar
        :visible="sidebarVisible"
        @update:visible="sidebarVisible = $event"
      />
  
      <!-- Bulk Upload Modal -->
      <b-modal id="bulkUploadModal" title="Bulk Upload Employees" @ok="doBulkUpload">
        <b-form-file
          v-model="csvFile"
          accept=".csv"
          placeholder="Choose a CSV file..."
        ></b-form-file>
        <small class="text-muted">
          Expect CSV columns: empId,Name,email,contactno,department,title,location,ctc,inhand,presentDays,travel_allowance,advance_salary,deduction,leaves,weekoff,holidays
        </small>
      </b-modal>
    </div>
  </template>
  
  <script>
  import {
    BTable, BAvatar, BBadge, BRow, BCol, BForm, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton, BCard, BModal, VBModal, BDropdown, BDropdownItem, BFormRadioGroup, VBPopover, BFormFile
  } from 'bootstrap-vue'
  import EmployeeTable from '@/views/apps/payroll/components/EmployeeTable.vue';
  import DepartmentRuleSidebar from './DepartmentRuleSidebar.vue';
  import payrollService from '@/services/payrollService.js';
  import vSelect from 'vue-select'
  export default {
    name: 'EmployeeListView',
    components: {
      EmployeeTable,
      DepartmentRuleSidebar,
      BTable,
      BAvatar,
      BBadge,
      BRow,
      BCol,
      BForm,
      BFormGroup,
      BFormSelect,
      BPagination,
      BInputGroup,
      BFormInput,
      BFormFile,
      BInputGroupAppend,
      BButton,
      BCard,
      BFormRadioGroup,
      vSelect,
      //BPopover,
      
      BModal
    },
    data() {
      return {
        employees: [],
        searchTerm: '',
        departmentFilter: '',
        departmentList: [{"title": "Sattva"},{"title": "HPE"},{"title": "KPMG"},{"title": "Nikitha"},{"title": "LnT"},{"title": "Reliance"},{"title": "William Sonoma"},{"title": "Hinduja"},{"title": "Adani Assam"},{"title": "Adani Bihar"},{"title": "Adani Mumbai"}], // or fetch dynamically
  
        sidebarVisible: false,
  
        // For bulk upload
        csvFile: null,
      };
    },
    computed: {
      filteredEmployees() {
        let list = [...this.employees];
        if (this.departmentFilter) {
          list = list.filter(e => e.department === this.departmentFilter);
        }
        if (this.searchTerm) {
          const term = this.searchTerm.toLowerCase();
          list = list.filter(e => {
            return (
              (e.Name && e.Name.toLowerCase().includes(term)) ||
              (e.email && e.email.toLowerCase().includes(term))
            );
          });
        }
        return list;
      },
    },
    methods: {
      fetchEmployees() {
        payrollService.getEmployees(this.departmentFilter)
          .then(res => {
            this.employees = res.data;
            console.log('EMployee list: ', res)
          })
          .catch(err => console.error(err));
      },
      showSidebar() {
        this.sidebarVisible = true;
      },
      openBulkUploadModal() {
        this.csvFile = null;
        this.$bvModal.show('bulkUploadModal');
      },
      doBulkUpload() {
        // In real code, parse the CSV, transform it to JSON, then call the bulk upload service
        // For example, you can use PapaParse or a similar library:
        if (!this.csvFile) return;
  
        const reader = new FileReader();
        reader.onload = async e => {
          const csvText = e.target.result;
          // parse CSV to array of objects...
          // For brevity, let's pretend parseCsvData(csvText) returns an array
          // We'll do a simple demonstration:
          const lines = csvText.split('\n').map(line => line.trim()).filter(Boolean);
          // Lines, ignoring the header for a quick example
          const [header, ...rows] = lines;
          const columns = header.split(',');
  
          const employeesData = rows.map(row => {
            const values = row.split(',');
            const empObj = {};
            columns.forEach((col, i) => {
              empObj[col] = values[i];
            });
            return empObj;
          });
  
          // call the bulk upload endpoint
          payrollService.bulkUploadEmployees(employeesData)
            .then(() => {
              this.$bvToast.toast('Bulk upload successful', { variant: 'success' });
              this.fetchEmployees();
            })
            .catch(err => {
              this.$bvToast.toast('Bulk upload error', { variant: 'danger' });
              console.error(err);
            });
        };
        reader.readAsText(this.csvFile);
      },
    },
    created() {
      this.fetchEmployees();
    },
  };
  </script>
  <style scoped lang="scss">

  @import "@core/scss/vue/libs/vue-select.scss";
//   @import '@core/scss/vue/libs/vue-flatpicker.scss';

</style>
  